<template id="coach_videotag">
  <b-container fluid>
    <b-card v-if="coach">
      <b-row align-v="center">
        <b-col cols="1">
          <avatar
            :avatar="
              coach.allenatore
                ? coach.allenatore.avatar_url
                : '/assets/images/agente.png'
            "
          />
        </b-col>
        <b-col>
          <h5>
            {{
              coach.allenatore
                ? coach.allenatore.cognome + " " + coach.allenatore.nome
                : coach.name
            }}
          </h5>
        </b-col>
        <b-col>
          <b-btn
            variant="primary"
            title="Seleziona partita"
            class="mr-1"
            @click="showPartite"
            >Seleziona partita</b-btn
          >
          <b-btn
            variant="primary"
            title="Archivio tags"
            class="mx-1"
            @click="showArchivio"
            >Archivio tags</b-btn
          >
          <span class="font-size-lg ml-5"> {{ utente }} </span>
        </b-col>
      </b-row>
    </b-card>

    <div v-if="loading_match" class="text-center">
      <h4>
        Attendere...
        <img :src="loadingImg" style="width: 150px" />
      </h4>
    </div>

    <div v-if="partita && !loading_match" class="mt-2">
      <b-input-group
        prepend="Tactical video url"
        v-if="video && !video.tactical_url"
      >
        <b-form-input
          v-model="video_url"
          placeholder="Insert video url"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            :variant="waiting ? 'warning' : 'primary'"
            @click="salvaVideo"
            :disabled="waiting"
            >Salva
            <b-icon
              icon="arrow-repeat"
              :animation="waiting ? 'spin' : ''"
            ></b-icon
          ></b-button>
        </b-input-group-append>
      </b-input-group>
      <videotag :goalVisible="false"></videotag>
    </div>

    <b-modal
      id="matchesModal"
      ref="matchesModal"
      :title="!show_archivio ? 'Search match' : 'Archivio tags'"
      ok-only
      ok-title="Close"
    >
      <div v-if="!show_archivio">
        <div v-if="show_aree">
          <span>Select an area: </span>
          <b-form-input
            v-model="search_area"
            placeholder="Quick search"
          ></b-form-input>
          <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
            <b-row>
              <b-col
                cols="3"
                v-for="area in filtered_aree"
                style="padding: 0.2rem"
                :key="area.id"
              >
                <b-card
                  class="small text-center"
                  style="cursor: pointer; height: 100%"
                  @click="selezionaArea(area.id)"
                >
                  <flag :country="area.sigla" :size="24"></flag><br />
                  {{ area.paese }}
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-if="show_competizioni">
          <div v-if="is_search">
            <h4>Waiting... <img :src="loadingImg" style="width: 150px" /></h4>
          </div>
          <div v-if="!is_search && competizioni.length == 0">
            <h5>No competition found!</h5>
          </div>
          <div v-if="competizioni.length > 0">
            <span>Select a competition: </span>
            <b-form-input
              v-model="search_competizione"
              placeholder="Quick search"
            ></b-form-input>
            <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
              <b-row>
                <b-col
                  cols="4"
                  v-for="comp in filtered_competizioni"
                  style="padding: 0.2rem"
                  :key="comp.id"
                >
                  <b-card
                    class="small text-center"
                    style="cursor: pointer; height: 100%"
                    @click="selezionaCompetizione(comp.id)"
                  >
                    <strong>{{ comp.nome }}</strong
                    ><br />
                    <span class="font-size-xs color-rosso">{{
                      comp.formato
                    }}</span
                    ><br />
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <div v-if="show_squadre">
          <div v-if="is_search">
            <h4>Waiting... <img :src="loadingImg" style="width: 150px" /></h4>
          </div>
          <div v-if="!is_search && squadre.length == 0">
            <h5>No teams found!</h5>
          </div>
          <div v-if="squadre.length > 0">
            <span>Select a team: </span>
            <b-form-input
              v-model="search_squadra"
              placeholder="Quick search"
            ></b-form-input>
            <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
              <b-row>
                <b-col
                  cols="4"
                  v-for="team in filtered_squadre"
                  style="padding: 0.2rem"
                  :key="team.id"
                >
                  <b-card
                    class="small text-center"
                    style="cursor: pointer; height: 100%"
                    @click="selezionaSquadra(team.id)"
                  >
                    <img :src="team.logo_url" width="50" /><br />
                    <strong>{{ team.officialName }}</strong
                    ><br />
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <div v-if="show_matches">
          <div v-if="is_search">
            <h4>Waiting... <img :src="loadingImg" style="width: 150px" /></h4>
          </div>
          <div v-if="!is_search && matches.length == 0">
            <h5>No match found!</h5>
          </div>
          <div v-if="matches.length > 0">
            <span>Select a match: </span>
            <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
              <b-row
                class="py-1"
                v-for="partita in matches"
                style="border-bottom: 1px solid #e5e5e5; cursor: pointer"
                @click="selezionaPartita(partita.wyscout_id, null)"
                :key="partita.wyId"
              >
                <b-col>
                  <h5>{{ partitaLabel(partita.squadre) }}</h5>
                </b-col>
                <b-col>
                  {{ partita.data | formatDateHour }}
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show_archivio">
        <p v-if="!videos.length">Nessuna partita con tags in archivio!</p>
        <div v-if="videos.length">
          <p>Trovate {{ videos.length }} partite con tags!</p>
          <hr />
          <div style="height: 400px; overflow-y: scroll; overflow-x: hidden">
            <div
              v-for="video in videos"
              :key="video.id"
              class="row"
              style="border-bottom: 1px solid #e5e5e5; cursor: pointer"
              v-on:click="selezionaPartita(video.partita.wyscout_id, video)"
            >
              <div class="col-md-9 my-1">
                <h5 style="display: inline">
                  {{ partitaLabel(video.partita.squadre) }}
                  <b-icon
                    class="ml-2"
                    title="Has tactical video"
                    v-if="video.video"
                    icon="camera-reels-fill"
                  ></b-icon>
                </h5>
                <br />
                <span class="text-muted">{{
                  video.partita.competizione.nome
                }}</span
                ><br />
                <span class="text-muted">{{
                  video.partita.data | formatDateHour
                }}</span
                ><br />
                <span v-if="video.utente" class="color-rosso">{{
                  video.utente.nomeCompleto
                }}</span
                ><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import moment from "moment";
import Auth from "@/lib/auth.js";
import Avatar from "@/components/Avatar.vue";
import loadingImg from "@/assets/images/loading.gif";
import Videotag from "@/components/videotag/Home.vue";
import Flag from "@/components/Flag.vue";
export default {
  components: {
    Avatar,
    Videotag,
    Flag,
  },

  data: function () {
    return {
      coach: null,
      partita: null,
      videos: [],
      loading: false,
      loadingImg,
      squadra: null,
      matches: [],
      loading_match: false,
      data: [],
      show_archivio: false,
      utente: "",
      video_url: null,
      video: null,
      waiting: false,

      aree: [],
      competizioni: [],
      squadre: [],
      search_area: "",
      search_competizione: "",
      search_squadra: "",
      show_aree: false,
      show_competizioni: false,
      show_squadre: false,
      show_matches: false,
      is_search: false,
    };
  },

  created: function () {
    this.getAree();
    this.getAllenatore();
  },

  computed: {
    auth: function () {
      return Auth;
    },
    user: function () {
      return Auth.user;
    },
    filtered_aree: function () {
      if (this.search_area != "") {
        return this.aree.filter((area) =>
          area.paese.toLowerCase().includes(this.search_area.toLowerCase())
        );
      }
      return this.aree;
    },
    filtered_competizioni: function () {
      if (this.search_competizione != "") {
        return this.competizioni.filter((competizione) =>
          competizione.nome
            .toLowerCase()
            .includes(this.search_competizione.toLowerCase())
        );
      }
      return this.competizioni;
    },
    filtered_squadre: function () {
      if (this.search_squadra != "") {
        return this.squadre.filter((squadra) =>
          squadra.officialName
            .toLowerCase()
            .includes(this.search_squadra.toLowerCase())
        );
      }
      return this.squadre;
    },
  },

  methods: {
    getAree() {
      this.$http.get("/ws/aree").then((response) => {
        this.aree = response.data;
      });
    },

    getAllenatore() {
      this.$http
        .get("/scouting/view/allenatore/" + this.$route.params.id + "/1")
        .then((response) => {
          this.coach = response.data;
          if (this.coach && this.coach.allenatore) {
            this.$http
              .get("/videotag/partite/allenatore/" + this.coach.allenatore.id)
              .then((response) => {
                this.videos = response.data;
              });
          }
          if (!this.coach.id) {
            this.$store.commit("msgPush", {
              msg: "Coach not found!",
              status: 0,
            });
            this.$router.push({ name: "ScoutingCoaches" });
          }
        });
    },

    showArchivio() {
      this.show_archivio = true;
      this.$refs.matchesModal.show();
    },

    showPartite() {
      this.show_archivio = false;
      this.show_aree = true;
      this.show_competizioni = false;
      this.show_squadre = false;
      this.show_matches = false;
      this.search_area = "";
      this.search_competizione = "";
      this.search_squadra = "";

      this.$refs.matchesModal.show();
    },

    selezionaArea: function (paese_id) {
      this.show_aree = false;
      this.competizioni = [];
      this.squadre = [];
      this.matches = [];
      this.is_search = true;
      this.show_competizioni = true;
      this.$http.get("/scouting/competizioni/" + paese_id).then((response) => {
        this.competizioni = response.data;
        this.is_search = false;
      });
    },

    selezionaCompetizione: function (comp_id) {
      this.show_competizioni = false;
      this.squadre = [];
      this.matches = [];
      this.is_search = true;
      this.show_squadre = true;
      this.$http
        .get("/scouting/competizione/squadre/" + comp_id)
        .then((response) => {
          this.squadre = response.data;
          this.is_search = false;
        });
    },

    selezionaSquadra: function (squadra_id) {
      this.show_squadre = false;
      this.matches = [];
      this.is_search = true;
      this.show_matches = true;
      this.$http
        .get("/scouting/squadra/partite/" + squadra_id)
        .then((response) => {
          this.matches = response.data;
          this.is_search = false;
        });
    },

    partitaLabel(squadre) {
      if (squadre.length > 0) {
        if (squadre[0].pivot.side == "home") {
          return squadre[0].name + " - " + squadre[1].name;
        } else return squadre[1].name + " - " + squadre[0].name;
      } else {
        return "Home - Away";
      }
    },

    selezionaPartita: function (ws_partita_id, video) {
      this.loading_match = true;
      this.partita = ws_partita_id;
      var id_video = 0;
      if (video) {
        id_video = video.id;
        if (video.utente) {
          this.utente = video.utente.nickname;
        }
      } else {
        this.utente = "";
      }
      this.$http
        .get(
          "/videotag/partita/allenatore/" +
            this.partita +
            "/" +
            this.coach.allenatore.id +
            "/" +
            id_video
        )
        .then((response) => {
          this.data = response.data;
          this.video = this.data.video;
          this.$store.commit("videotag/cancelNewTag");
          this.$store.commit("videotag/setPrivateTag", false);
          this.$store.commit("videotag/setEditor", false);
          this.$store.commit("videotag/updateData", this.data);
          this.loading_match = false;
        });
      this.$refs.matchesModal.hide();
    },

    salvaVideo: function () {
      if (this.video_url && this.video_url != "") {
        this.waiting = true;
        this.$http
          .get("/videotag/update/video/" + this.video.id, {
            params: { url: this.video_url },
          })
          .then((response) => {
            this.waiting = false;
            this.selezionaPartita(this.partita, null);
          });
      }
    },
  },
  filters: {
    formatDateHour: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
  },
};
</script>
